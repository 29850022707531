<template>
  <comprehensive>
    <div v-loading="pageLoading">
      <div class="stepTop1">
        <img
          src="./img/stepTop1.png"
          style="width: 1068px; height: 25px; margin-top: 31px"
          v-if="initInfo.Status == null"
        />
        <img
          src="./img/stepTop2.png"
          style="width: 1068px; height: 25px; margin-top: 31px"
          v-if="initInfo.Status == 0 || initInfo.Status == 2"
        />
        <img
          src="./img/stepTop3.png"
          style="width: 1068px; height: 25px; margin-top: 31px"
          v-if="initInfo.Status == 1"
        />
        <div class="stepTopText" style="color: #999999; font-size: 16px">
          <span style="padding-left: 217px">资料提交</span>
          <span style="padding-left: 200px">平台审核</span>
          <span style="padding-left: 195px">入驻成功</span>
        </div>
      </div>

      <div class="stepTop2" v-if="initInfo.Status == 0">
        <div
          style="
            color: #999999;
            font-size: 16px;
            display: flex;
            align-items: center;
          "
        >
          <img
            src="./img/stepImgZy1.png"
            style="width: 38px; height: 38px; margin-right: 14px"
          />
          您的资料已成功提交!
        </div>
        <div style="color: #666666; font-size: 16px; margin-top: 16px">
          请在“账户-<span
            style="color: #ef2147; margin: 0px 10px; cursor: pointer"
            @click="JumpCenter(5)"
          >
            权限申请</span
          >”页面，查看并跟踪审批结果。
        </div>
      </div>
      <div class="stepTop2" v-if="initInfo.Status == 1">
        <div
          style="
            color: #999999;
            font-size: 16px;
            margin-top: 23px;
            display: flex;
            align-items: center;
          "
        >
          <img
            src="./img/stepImgZy2.png"
            style="width: 38px; height: 38px; margin-right: 14px"
          />
          恭喜！贵司入驻成功，成为平台供应商！
        </div>
        <div class="stepTop2btn" @click="goJC">查看集采信息</div>
      </div>
      <div class="stepTop2" v-if="initInfo.Status == 2">
        <div
          style="
            color: #999999;
            font-size: 16px;
            margin-top: 23px;
            display: flex;
            align-items: center;
          "
        >
          <img
            src="./img/stepImgZy3.png"
            style="width: 38px; height: 38px; margin-right: 10px"
          />
          很抱歉，贵司入驻失败！
        </div>
        <div style="color: #666666; font-size: 16px; margin: 16px auto">
          原因：{{ initInfo.AuditRemarks }}
        </div>
        <div class="stepTop2btn" @click="again">重新申请</div>
      </div>

      <div class="stepTop3">
        <div style="color: #333333; font-size: 22px">
          企业基本信息<span style="font-size: 14px"
            ><span style="color: red">【温馨提醒】</span
            >公司信息如需变更请联系平台:400-888-2566</span
          >
        </div>
        <div class="stepTable">
          <div class="stepTable_left" v-if="initInfo">
            <div class="stepTable_tr">
              <div class="stepTable_td">企业名称</div>
              {{ initInfo.EnterpName }}
            </div>
            <div class="stepTable_tr">
              <div class="stepTable_td">企业地址</div>
              <span
                class="hiddenOne"
                style="margin-right: 10px; margin-left: 0px"
              >
                {{ initInfo.Province }}
                {{ initInfo.City }}
                {{ initInfo.Area }}
                {{ initInfo.Address }}
              </span>
            </div>
            <div class="stepTable_tr">
              <div class="stepTable_td">指定联系人</div>
              {{ initInfo.AdminName }}
            </div>
          </div>
          <div class="stepTable_right">
            <div class="stepTable_tr">
              <div class="stepTable_td">联系方式</div>
              {{ initInfo.EnterpContactsPhone }}
            </div>
            <div class="stepTable_tr">
              <div class="stepTable_td">联系人手机</div>
              {{ initInfo.AdminPhone }}
            </div>
          </div>
        </div>

        <div class="contractAgreement" v-if="type == 0">
          <div style="color: #333333; font-size: 22px; margin-top: 24px">
            平台合作服务协议
          </div>
          <div class="contract">
            <div
              class="contractJiayi"
              style="
                text-align: left;
                display: flex;
                justify-content: space-between;
              "
            >
              甲方： 大汉电子商务有限公司
              <div
                v-if="form.ContractNo"
                class="contractJiayi"
                style="text-align: right"
              >
                合同编号： {{ form.ContractNo }}
              </div>
            </div>
            <div class="contractJiayi" style="text-align: left">
              乙方： {{ form.EnterpName }}
            </div>

            <div class="contractWenzi">
              根据《中华人民共和国民法典》及其他有关法律法规的规定， 甲、
              乙双方在自愿、 平等、 互惠互利的基础上， 经友好协商，
              就甲方及其分子公司 （下文统称甲方， 甲方分子公司详见 附件：
              甲方分子公司名单） 采购乙方产品事宜签订本合同， 以资双方共同遵守。
              <br />
              <span style="font-weight: bold">第一条、 合作内容</span>
              <br />
              本协议项下： 双方合作内容包括：
              <br />
              （一） 乙方使用大大买钢平台作为其销售报价窗口，
              销售双方指定的产品。
              <br />
              （二） 甲方通过大大买钢平台为乙方销售产品提供交易、
              技术支持和销售推广、 金融产品等系列服务。
              <br />
              <span style="font-weight: bold">第二条、 关于网上报价销售</span>
              <br />
              （一） 销售产品的标准及种类
              <br />
              乙方通过大大买钢平台销售的货物必须为符合国家标准和行业标准的产品，
              若违反前述 约定， 甲方有权向乙方追偿由此造成的各项损失，
              并有权协助大大买钢平台交易方向乙方追偿由此造成的各项损失。
              <br />
              （二） 网上报价主要涉及步骤及相关事项
              <br />
              1、 报价及销售
              <br />
              （1）
              乙方每日通过在大大买钢平台进行产品挂牌报价（挂牌产品应为真实可售、
              足量、 达标的现货资源）并依据产品所在市场参考价格定价。
              <br />
              （2）
              本协议签订后，乙方可以使用其在大大买钢平台开通的用户账号参与报价，乙方应妥善保存用户账号的密码及相关信息，甲乙双方应严格遵守国家法律规定，并分别承担因各自原因使账号泄露导致的相应后果。
              <br />
              2、 发货与提货
              <br />
              （1） 甲方在大大买钢平台上确定乙方价格后，
              以开具《发货通知函》的形式通知乙方，
              乙方应确认甲方《发货通知函》内的甲方提货专用章后方可放货。
              <br />
              （2）
              在提发货环节涉及的货款以外的费用由大大买钢平台客户自行承担，甲方不进行此类款项的垫付。
              <br />
              （3） 甲方已经开具《发货通知函》的情况下，若需变更提货人、
              提货车辆信息， 甲方将向乙方开具《变更发货通知函》。
              <br />
              （4） 本条各款所设《发货通知函》、
              《变更发货通知函》的样单及预留印鉴详见附件，
              上述文件由甲方指定人员以微信、
              电子邮箱或大大买钢平台发送至乙方指定的传真号码或接收人
              <input
                type="text"
                name="fax_number"
                v-model="form.Faxnumber"
                class="contractInput"
              />/<input
                type="text"
                name="receiver"
                v-model="form.Receiver"
                class="contractInput"
              />， 微信号<input
                type="text"
                name="we_chat"
                v-model="form.WechatNo"
                class="contractInput"
              />，电话号码
              <div style="display: contents">
                <input
                  type="text"
                  name="mobile"
                  v-model="form.MobliePhone"
                  class="contractInput"
                />
              </div>
              为有 效， 乙方应当执行前述样单的各项指示。
              <br />
              （5） 甲乙双方共同确认，本条各款所设《发货通知函》
              、《变更发货通知函》是双方指定的货权转移指令，
              且上述指令应当通过指定的传真号、电子邮箱或指定聊天软件及账号消息通知进行传递。
              其他任何形式的货权转移指令，包括但不限于人员电话通知、非指定聊天软件及账号消息通知、
              口头告知等形式，
              都不作为有效的货权转移指令（双方另有加盖公司公章的书面约定除外）。
              乙方未按上述约定的货权转移指令方式交付货物，
              不视为乙方履行交货义务， 甲方有权要求乙方继续向甲方交付货物，
              若乙方拒绝交货即构成根本违约，
              乙方不得以表见代理或其他理由免除交货义务 ，
              由此引起的法律及经济责任由乙方承担。
              <br />
              3、 货款结算
              <br />
              （1） 甲乙双方按双方确认的产品实际成交价格结算。
              <br />
              （2） 付款方式：现款现货；另有约定的除外。
              <br />
              （3） 双方于每月开始的
              <input
                @keyup="onInputPayDay"
                name="payment_settlement_day"
                placeholder="请输入整数，1~31"
                class="contractInput"
                v-model="form.PayDay"
              />日内核对上月全部账目， 并以双方盖章确认的对账单确
              定上月发票及余款数据。
              <br />
              （4）
              本协议项下所有交易适用的发票种类为增值税专用发票，现行增值税税率为13%；
              如遇国家税收政策变化， 在新税率实施前的，
              乙方仍应按原税率向甲方开具发票，
              若乙方无法及时开具相应发票的，应向甲方补偿因税率变化产生的税差及其他损失。
              <br />
              （5）
              乙方每月25日前一次性将上月剩余未开具的及当月已经结算部分的增值税发票开具给甲方。
              双方应按下列方式交付相关票据：
              <br />
              甲方接收人：张曼， 联系电话：15999296877，
              邮寄地址：湖南省长沙市望城区普瑞大道西金桥国际未来城4栋3楼大大买钢。
              <br />
              乙方接收人：<input
                type="text"
                name="payment_settlement_receiver"
                v-model="form.PayReceiver"
                class="contractInput"
              />， 联系电话：<input
                type="text"
                name="payment_settlement_mobile"
                v-model="form.PayPhone"
                class="contractInput"
              />， 邮寄地址：<input
                type="text"
                v-model="form.PayAddress"
                name="payment_settlement_address"
                class="contractInput"
              />。
              <br />
              4、
              甲方收货后如有数量异议或者质量异议的，乙方应在收到甲方书面异议后5日内派人处理并提出解决方案，
              由此产生费用的， 由责任方承担。
              <br />
              5、 相关事项
              <br />
              （1）按时交货
              <br />
              如乙方未能备齐货物或未能按时交货，
              乙方应于甲方通知乙方后一个工作日内， 退还未交付货物对应的货款。
              <br />
              （2）货物安全
              <br />
              因乙方的原因包括但不限于发生质量问题、权属争议、未按时交货、逾期未开具发票等，
              由乙方负责处理及赔偿， 如因乙方原因导致甲方被追究任何法律责任的，
              甲方有权要求乙方承担全额赔偿责任。
              <br />
              （3） 时间约定
              <br />
              如在《发货通知函》中注明的时间范围内，
              非因甲方、大大买钢平台客户的原因或不可抗力，
              造成大大买钢平台客户无法按时提货或无法足量提货，
              导致甲方被大大买钢平台客户追究任何法律责任的，
              甲方有权要求乙方承担全额赔偿责任。
              <br />
              （4） 收退款指定账号：
              <br />
              甲方收款指定账号：
              <br />
              账号： 8111601012100312273
              <br />
              户名： 大汉电子商务有限公司
              <br />
              开户行： 中信银行长沙红旗区支行
              <br />
              甲方退款指定账号：
              <br />
              账号： 8111601012100312273
              <br />
              户名： 大汉电子商务有限公司
              <br />
              开户行： 中信银行长沙红旗区支行
              <br />
              乙方收款指定账号：
              <br />
              账号：
              <input
                type="text"
                name="party_b_collection_account"
                v-model="form.BsNum"
                class="contractInput"
              />
              <br />
              户名： {{ form.EnterpName }} <br />
              开户行：
              <input
                type="text"
                name="party_b_collection_bank"
                v-model="form.BsBank"
                class="contractInput"
              />
              <br />
              <span style="font-weight: bold">第三条、 保密条款</span>
              <br />
              1、
              甲乙双方对于因本协议从对方获取的文件资料和商业秘密负有保密义务。双方应采取合理有效的保密措施，
              避免对方保密信息被无关人员知晓。
              <br />
              2、 因违反保密条款给对方造成损失的， 违约方应向对方承担赔偿责任。
              <br />
              3、
              除非公众已知悉或已通过合法途径公开披露的，该等保密义务不因本协议的无效、
              解除、 履行完毕而终止。
              <br />
              <span style="font-weight: bold">第四条、 违约责任</span>
              <br />
              1、
              本合同签订后，任何一方违反本合同的相关约定，违约方应按合同项下单笔订单总金额每
              日向守约方支付0.5‰的违约金，违约金不足以弥补守约方损失的，应赔偿守约方损失。
              如违约方违约情形超过<input
                type="text"
                name="default_overtake_day"
                v-model="form.DefaultmoreDay"
                class="contractInput"
              />日的， 守约方有权终止合同。
              <br />
              2、
              乙方如因发生质量问题、权属争议、未按时交货、逾期未开具发票等原因，导致甲方受到损失的，
              乙方应承担全额赔偿责任。
              <br />
              3、
              乙方放货前，未确认甲方《发货通知函》内的甲方提货专用章而放货的，应承担由此导致的甲方的一切损失。
              <br />
              4、 其他违约情形按《中华人民共和国民法典》执行。
              <br />
              <span style="font-weight: bold">第五条、 争议解决</span>
              <br />
              本协议在履行过程中发生争议时， 双方应协商解决； 协商不成的，
              向甲方所在地有管辖权的人民法院提起诉讼， 因诉讼产生的一切费用，
              包括但不限于案件受理费、 鉴定费、 保全费、
              合理的律师费等由败诉方承担。
              <br />
              <span style="font-weight: bold">第六条、 合作期限</span>
              <br />
              双方合作期限自本协议生效之日起至2024年12月31日止。
              协议期满前一个月内， 双方如需继续合作的可续签协议；
              如双方到期未续签协议， 本协议自动终止。 合作期限内，
              甲乙双方有权视合作情况暂停乙方报价或解除本协议， 并及时通知对方。
              <br />
              <span style="font-weight: bold">第七条、 其他</span>
              <br />
              1、 甲乙双方合作期间，乙方如需变更指定传真号、联系人资料、
              银行账户资料等重要信 息，
              可致电大大买钢平台客服热线（400-888-2566）并根据指示办理变更操作。
              <br />
              2、
              甲乙双方合作期间，若乙方发现甲方工作人员或其他第三方人员的行为超出本协议约定的范围，
              应当通过客服热线或其他方式与甲方核实确认。
              甲方工作人员或其他第三方人员越权代理的行为，
              在未经甲方书面确认前属于无效代理行为， 不构成表见代理。
              若甲方对单次具体的越权代理的行为予以追认，
              不视为甲方追认该代理人的其他越权代理行为。
              越权代理行为及甲方的追认属于独立行为， 不构成双方交易习惯。
              <br />
              3、
              乙方确认，除非经另行书面通知变更，本协议落款处所列示的乙方单位地址同时
              作为 其在本协议纠纷解决时 （包括一审、 二审和执行程序）
              相关文件和法律文书的送达地址。
              <br />
              4、 本协议所有约定均适用于甲方分公司及甲方控股的子公司和乙方，
              本协议附件中的甲方分子公司与乙方之间所有基于本协议发生的交易，
              任何一方均有权基于本协议向另一方主张权利。
              <br />
              5、 本协议经双方签订之日起生效。
              本协议未尽事宜，双方另行协商并签订书面补充协议。
              <br />
              6、 本协议一式肆份， 甲方叁份， 乙方壹份， 具有同等法律效力。
              <br />
              7、 乙方确认已阅读、知晓并充分理解大大买钢平台公示的相关规则，
              并对本协议承诺事项作出承诺，愿意承担本协议确定的各项责任。
              <br />
              附件 1： 《发货通知函》
              <br />
              附件 2： 《变更发货通知函》
              <br />
              附件 3： 《分子公司名录》
              <br />
              附件 4： 《廉洁协议》
              <br />
              <div class="bottom" style="">
                <ul style="margin: 0; padding: 0">
                  <li>
                    <span style="padding-right: 444px"
                      >甲　　　方：大汉电子商务有限公司</span
                    ><span>乙　　　方：{{ form.EnterpName }}</span>
                  </li>
                  <li style="margin-top: 20px">
                    <span style="padding-right: 216px"
                      >地　　　址：湖南省长沙市望城区经济开发区
                      普瑞大道南侧金桥国际</span
                    ><span
                      >地　　　址：<input
                        type="text"
                        name="address"
                        v-model="form.Address"
                        class="contractInput"
                    /></span>
                  </li>
                  <li style="margin-top: 20px">
                    <span style="padding-right: 604px">法定代表人：</span
                    ><span
                      >法定代表人：<input
                        type="text"
                        name="representative"
                        v-model="form.Legalperson"
                        class="contractInput"
                    /></span>
                  </li>
                  <li style="margin-top: 20px">
                    <span style="padding-right: 604px"> 联系　电话：</span
                    ><span
                      >联系　电话：<input
                        type="text"
                        name="phone"
                        v-model="form.Phone"
                        class="contractInput"
                    /></span>
                  </li>
                </ul>
              </div>

              <div style="text-align: center">
                <img
                  src="./img/supplier_company_contract1.png"
                  style="width: 1008px"
                />
                <img
                  src="./img/supplier_company_contract2.png"
                  style="width: 1008px"
                />
                <img
                  src="./img/supplier_company_contract3.png"
                  style="width: 1008px"
                />
              </div>
              <div class="contractText">廉洁协议</div>
              <div
                style="
                  margin: 30px auto;
                  display: flex;
                  justify-content: space-between;
                  text-indent: 0em;
                "
              >
                <div style="text-align: left">
                  <div class="contractJiayi" style="text-align: left">
                    甲方（采购方全称）：大汉电子商务有限公司
                  </div>
                </div>
                <div style="text-align: left">
                  <div class="contractJiayi" style="text-align: left">
                    乙方（供应方全称）：{{ form.EnterpName }}
                  </div>
                </div>
              </div>
              <div class="contractWenzi">
                甲乙双方签署了<span style="font-weight: bold"
                  >大大买钢《平台合作服务协议》</span
                >
                合同（以下简称原合同），为营造健康
                商业环境和建立正常商业合作关系，甲乙双方经友好协商签订本协议，并作为双方共同遵守的廉
                洁合作行为准则执行。<br />
                <span style="font-weight: bold">一、甲方责任</span>
                <br />1、甲方有责任向乙方介绍大汉（即甲方或甲方所在的大汉集团）廉洁合作相关规定。
                <br />
                2、甲方严禁甲方人员在业务合作过程出现下列行为（包括不限于）：
                <br />（1） 商业贿赂：
                甲方人员收受或向乙方及乙方人员主动索要各种形式的回扣、现金、实物、证券、礼券等有
                价物品，或接受并参与乙方及乙方人员提供的旅游、高档宴请及其他可能影响职务行为公正履行
                的活动； <br />（2） 行贿及其他：
                甲方人员要求乙方或乙方人员配合或协助进行各种形式的行贿（包括但不限于提供回扣、现
                金、实物、证券、礼券等有价物品）、不当利益输送在内的各类违法活动；
                <br />（3） 利益冲突：
                甲方人员未经甲方批准，以本人或其亲属名义于乙方或其关联单位处直接或变相参股、任职、兼
                职或获取其他利益，甲方禁止引入大汉离职人员注册或参股的公司成为大汉供应商；
                <br />（4） 串标围标：
                在甲方业务范围内，甲方员工授意乙方配合进行串标或围标等违规违法行为；
                <br />
                （5） 资金往来及关联交易：
                甲方人员（含甲方人员授意的其他人员，如甲方人员的亲属、朋友、同学、下属等）以任何
                理由向乙方（含关联单位）或乙方人员借贷资金、要求垫资、担保或其他形式融资行为，以及通
                过房屋买卖、代持房产等其他使得甲方人员利用职务之便获取不当利益或损害甲方品牌、商誉、
                利益等（包括但不限于甲方认为甲方人员未公正履行职务）的关联交易行为。
                <br />
                3、对于乙方举报甲方人员违反廉洁合作禁止项的情况，甲方负有保密义务，并应及时组织
                调查，将最终调查结果反馈乙方，并可依据甲方或甲方所在大汉集团举报奖励政策给予乙方适当
                奖励。 <br />
                <span style="font-weight: bold">二、乙方责任</span>
                <br />1、乙方应保证乙方人员了解本协议中廉洁合作禁止项的相关规定，并遵照执行。
                <br />
                2、乙方承诺并保证，与甲方开展合作时不存在（且在与甲方开展合作的过程中不得有）：
                <br />（1）
                大汉在职员工直接或变相持股或控制乙方公司或其关联单位，但公开市场股票投资不在前述限制；
                <br />（2）
                大汉在职员工在乙方公司或其关联单位处任职、兼职、借贷资金或获取其他利益的情况。
                <br />
                3、乙方及乙方应确保乙方人员严格遵守以下廉洁合作约定（包括不限于）：
                <br />
                （1）
                严禁商业贿赂：乙方及乙方人员不得向甲方人员提供任何形式的回扣、现金、实物、证
                券、礼券等有价物品或旅游、高档宴请等其他可能影响职务行为公正履行的活动；乙方及乙方人
                员不得向甲方聘请的第三方单位及人员提供任何形式的赠与、贿赂等，以谋求在检测、鉴定、验
                收等方面的便利；乙方及乙方人员也不得接受甲方的承包商、供应商等相关单位及人员任何形式
                的赠予、贿赂等，以提供在检测、鉴定、验收等方面的便利； <br />
                （2）
                严禁行贿及其他违法：乙方及乙方人员不得接受甲方人员要求配合或协助进行各种形式
                的行贿（包括但不限于提供回扣、现金、实物、证券、礼券等有价物品）、不当利益输送在内违
                法行为的请求； <br />
                （3）
                利益冲突及其他披露：乙方在与甲方开展合作前，如出现以下任何情形之一，须在投标
                前，主动以书面方式向甲方完整、如实披露相关信息；若在与甲方合作过程中，出现以下任何情
                形之一的，须在相关情形发生后 10
                天内，以书面方式向甲方完整、如实披露相关信息：
                ①大汉在职员工的近亲属于乙方公司或其关联单位处直接或变相持股（注：公开市场股票投
                资不属于乙方协议披露义务）、控制、任职、兼职、借贷资金或获取其他利益的情况；
                ②大汉离职员工或其近亲属于乙方公司或其关联单位处直接或变相持股（注：公开市场股票
                投资不属于乙方协议披露义务）、控制、任职或兼职的情况；
                ③乙方法定代表人、股东、实际业务控制人、对接人两年内以其他公司名义参与大汉业务合
                作的情况。 <br />
                （4）
                严禁串标围标：在甲方业务范围内，乙方及乙方人员应拒绝甲方员工授意配合进行串标
                或围标等违规违法操作的请求；乙方及乙方员工不得串谋其他单位，在大汉业务中进行串标或围
                标等违规违法操作。 <br />
                （5）
                资金往来及关联交易：乙方人员（含乙方人员授意的其他人员，如乙方人员的亲属、朋
                友、同学、下属等）以任何理由向甲方
                （含关联单位）或甲方人员借贷资金、要求垫资、担保或其
                他形式融资行为，以及双方存在房屋买卖（合同款抵房除外）、代持房产等其他形式的关联交易，
                利用甲方人员职务之便获取不当利益或损害甲方品牌、商誉、利益等行为。
                <br />
                4、乙方在与甲方开展合作前，有责任向甲方披露乙方实际控制人信息，包括不限于姓名、身
                份证号码等信息。 <br />
                5、乙方有责任接受甲方对乙方在合作期间廉洁合作协议执行情况的监督，并对甲方相关调查工
                作主动配合。 <br />
                6、乙方有责任就甲方人员或甲方业务合作单位人员营私舞弊、弄虚作假等违反职业道德准则的
                行为及时向甲方举报，乙方在主动举报的情形下，其参与的上述行为将予以免责。
                甲方受理举报的常设部门及联系方式为： <br />（1）
                举报受理部门：大汉控股集团有限公司监察部 <br />（2）
                举报邮箱：jiancha@dahangroup.com
                <br />（3）举报电话：0731-88207935
                <br />（4）举报地址：湖南省长沙市望城区普瑞大道 1555
                号金桥国际未来城 4 栋 512 办公室 <br />
                <span style="font-weight: bold">三、违约责任</span> <br />
                1、如乙方或其人员违反本协议第二条乙方责任中第 2 款、第 3
                款约定的，甲方有权解除原合
                同且无需承担任何违约责任，乙方除应向甲方赔偿由此给甲方造成的损失外，乙方还承诺向甲方
                支付相当于合同总价
                30%或违规事件所涉及金额之和的十倍作为违约金（二者从高）。对乙方相
                关责任人员涉嫌违法犯罪的，甲方有权提交司法机关处理，有权公开通报处理情况。
                <br />
                2、如乙方拒不配合甲方对乙方在合作期间内的廉洁合作监督及调查工作的，或者被证实存在隐
                瞒信息、提供虚假信息和伪证行为的，乙方同意甲方有权视情节严重程度，对原合同的履行采取暂停
                支付业务进度款、终止合作、追究经济损失、解除合同等措施，且无需承担任何违约责任。
                <br />
                3、如乙方在合作期间内出现违反本协议约定的行为，甲方有权视情节严重程度，将乙方及其关
                联单位列入不诚信合作方名单，在甲方/大汉内外进行公示。 <br />
                <span style="font-weight: bold">四、其他</span> <br />
                1、本协议是原合同的补充协议，与原合同有同等法律效力。本合同未作约定的事项，仍以原合
                同约定为准。本协议约定与原合同不一致的，以本协议为准 <br />
                2、本协议经双方盖章后生效。
                <div class="bottom">
                  <ul style="margin: 0; padding: 0">
                    <li>
                      <span style="padding-right: 444px"
                        >甲　　　方：大汉电子商务有限公司</span
                      ><span>乙　　　方：{{ form.EnterpName }}</span>
                    </li>
                    <li style="margin-top: 20px">
                      <span style="padding-right: 604px"> 盖　　　章：</span
                      ><span>盖　　　章：</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="contractAttachment" v-if="type == 1">
          <div style="color: #333333; font-size: 22px; margin-top: 24px">
            平台合作服务协议
            <span style="margin-left: 24px; color: #666666; font-size: 14px"
              >请打印《平台合作服务协议》,签署盖章后上传图片</span
            >
          </div>
          <div
            style="display: flex; align-items: center"
            v-if="initInfo.Status != 1"
          >
            <div class="uploadDivTag">
              <img
                src="./img/xia.svg"
                style="width: 14px; height: 14px; margin-right: 6px"
              />
              平台合作服务协议
            </div>
            <div>
              <span
                class="contract_edit contractButton"
                @click="edit"
                v-if="type != 0 && initInfo.Status == null"
                >修改</span
              >
              <span
                class="contract_print contractButton"
                @click="getPrint"
                v-if="type != 0 && initInfo.Status == null"
                >在线打印</span
              >
              <span
                class="contract_print contractButton"
                @click="lookPrint"
                v-if="initInfo.Status != null"
                >查看</span
              >
            </div>
          </div>
          <div class="uploadDiv">
            <ddmg-upload-img
              :number="9"
              v-model="imgUrlArr"
              v-if="initInfo.Status == null"
            ></ddmg-upload-img>
            <!--            <el-upload-->
            <!--              v-if="initInfo.Status == null"-->
            <!--              action="#"-->
            <!--              list-type="picture-card"-->
            <!--              :before-upload="beforeAvatarUpload"-->
            <!--              :http-request="UploadUrl"-->
            <!--              :show-file-list="false"-->
            <!--            >-->
            <!--              <i class="el-icon-plus"></i>-->
            <!--            </el-upload>-->
            <div
              class="uploadView showImagePreview"
              v-for="(item, index) in imgUrlArr"
              :key="index"
              v-if="
                initInfo.Status == 0 ||
                initInfo.Status == 1 ||
                initInfo.Status == 2
              "
            >
              <img
                class="uploadLattice"
                :src="item"
                @click="handlePictureCardPreview(item)"
              />
              <!--              <div class="imgLattice" v-if="initInfo.Status == 0" @click="delImg(item)"></div>-->
              <!--              <div class="uploadViewText">{{ item.attachment_name }}</div>-->
            </div>
            <el-dialog :visible.sync="dialogVisible" width="80%">
              <img width="100%" :src="dialogImageUrl" alt="大大买钢" />
            </el-dialog>
            <el-dialog :visible.sync="dialogVisible2" width="80%">
              <div class="contractAgreement">
                <div style="color: #333333; font-size: 22px; margin-top: 24px">
                  平台合作服务协议
                </div>
                <div class="contract">
                  <div
                    class="contractJiayi"
                    style="
                      text-align: left;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    甲方： 大汉电子商务有限公司
                    <div
                      v-if="form.ContractNo"
                      class="contractJiayi"
                      style="text-align: right"
                    >
                      合同编号： {{ form.ContractNo }}
                    </div>
                  </div>
                  <div class="contractJiayi" style="text-align: left">
                    乙方： {{ form.EnterpName }}
                  </div>
                  <div class="contractWenzi">
                    根据《中华人民共和国民法典》及其他有关法律法规的规定， 甲、
                    乙双方在自愿、 平等、 互惠互利的基础上， 经友好协商，
                    就甲方及其分子公司 （下文统称甲方， 甲方分子公司详见 附件：
                    甲方分子公司名单） 采购乙方产品事宜签订本合同，
                    以资双方共同遵守。
                    <br />
                    <span style="font-weight: bold">第一条、 合作内容</span>
                    <br />
                    本协议项下： 双方合作内容包括：
                    <br />
                    （一） 乙方使用大大买钢平台作为其销售报价窗口，
                    销售双方指定的产品。
                    <br />
                    （二） 甲方通过大大买钢平台为乙方销售产品提供交易、
                    技术支持和销售推广、 金融产品等系列服务。
                    <br />
                    <span style="font-weight: bold"
                      >第二条、 关于网上报价销售</span
                    >
                    <br />
                    （一） 销售产品的标准及种类
                    <br />
                    乙方通过大大买钢平台销售的货物必须为符合国家标准和行业标准的产品，
                    若违反前述 约定， 甲方有权向乙方追偿由此造成的各项损失，
                    并有权协助大大买钢平台交易方向乙方追偿由此造成的各项损失。
                    <br />
                    （二） 网上报价主要涉及步骤及相关事项
                    <br />
                    1、 报价及销售
                    <br />
                    （1）
                    乙方每日通过在大大买钢平台进行产品挂牌报价（挂牌产品应为真实可售、
                    足量、 达标的现货资源）并依据产品所在市场参考价格定价。
                    <br />
                    （2）
                    本协议签订后，乙方可以使用其在大大买钢平台开通的用户账号参与报价，乙方应妥善保存用户账号的密码及相关信息，甲乙双方应严格遵守国家法律规定，并分别承担因各自原因使账号泄露导致的相应后果。
                    <br />
                    2、 发货与提货
                    <br />
                    （1） 甲方在大大买钢平台上确定乙方价格后，
                    以开具《发货通知函》的形式通知乙方，
                    乙方应确认甲方《发货通知函》内的甲方提货专用章后方可放货。
                    <br />
                    （2）
                    在提发货环节涉及的货款以外的费用由大大买钢平台客户自行承担，甲方不进行此类款项的垫付。
                    <br />
                    （3） 甲方已经开具《发货通知函》的情况下，若需变更提货人、
                    提货车辆信息， 甲方将向乙方开具《变更发货通知函》。
                    <br />
                    （4） 本条各款所设《发货通知函》、
                    《变更发货通知函》的样单及预留印鉴详见附件，
                    上述文件由甲方指定人员以微信、
                    电子邮箱或大大买钢平台发送至乙方指定的传真号码或接收人
                    <input
                      type="text"
                      name="fax_number"
                      v-model="form.Faxnumber"
                      class="contractInput"
                      disabled
                    />/<input
                      type="text"
                      name="receiver"
                      v-model="form.Receiver"
                      class="contractInput"
                      disabled
                    />， 微信号<input
                      type="text"
                      name="we_chat"
                      v-model="form.WechatNo"
                      class="contractInput"
                      disabled
                    />，电话号码
                    <div style="display: contents">
                      <input
                        type="text"
                        name="mobile"
                        v-model="form.MobliePhone"
                        class="contractInput"
                        disabled
                      />
                    </div>
                    为有 效， 乙方应当执行前述样单的各项指示。
                    <br />
                    （5） 甲乙双方共同确认，本条各款所设《发货通知函》
                    、《变更发货通知函》是双方指定的货权转移指令，
                    且上述指令应当通过指定的传真号、电子邮箱或指定聊天软件及账号消息通知进行传递。
                    其他任何形式的货权转移指令，包括但不限于人员电话通知、非指定聊天软件及账号消息通知、
                    口头告知等形式，
                    都不作为有效的货权转移指令（双方另有加盖公司公章的书面约定除外）。
                    乙方未按上述约定的货权转移指令方式交付货物，
                    不视为乙方履行交货义务，
                    甲方有权要求乙方继续向甲方交付货物，
                    若乙方拒绝交货即构成根本违约，
                    乙方不得以表见代理或其他理由免除交货义务 ，
                    由此引起的法律及经济责任由乙方承担。
                    <br />
                    3、 货款结算
                    <br />
                    （1） 甲乙双方按双方确认的产品实际成交价格结算。
                    <br />
                    （2） 付款方式：现款现货；另有约定的除外。
                    <br />
                    （3） 双方于每月开始的<input
                      type="text"
                      name="payment_settlement_day"
                      class="contractInput"
                      disabled
                      v-model="form.PayDay"
                    />日内核对上月全部账目， 并以双方盖章确认的对账单确
                    定上月发票及余款数据。
                    <br />
                    （4）
                    本协议项下所有交易适用的发票种类为增值税专用发票，现行增值税税率为13%；
                    如遇国家税收政策变化， 在新税率实施前的，
                    乙方仍应按原税率向甲方开具发票，
                    若乙方无法及时开具相应发票的，应向甲方补偿因税率变化产生的税差及其他损失。
                    <br />
                    （5）
                    乙方每月25日前一次性将上月剩余未开具的及当月已经结算部分的增值税发票开具给甲方。
                    双方应按下列方式交付相关票据：
                    <br />
                    甲方接收人：张曼， 联系电话：15999296877，
                    邮寄地址：湖南省长沙市望城区普瑞大道西金桥国际未来城4栋3楼大大买钢。
                    <br />
                    乙方接收人：<input
                      type="text"
                      name="payment_settlement_receiver"
                      v-model="form.PayReceiver"
                      disabled
                      class="contractInput"
                    />， 联系电话：<input
                      type="text"
                      name="payment_settlement_mobile"
                      v-model="form.PayPhone"
                      disabled
                      class="contractInput"
                    />， 邮寄地址：<input
                      type="text"
                      v-model="form.PayAddress"
                      name="payment_settlement_address"
                      disabled
                      class="contractInput"
                    />。
                    <br />
                    4、
                    甲方收货后如有数量异议或者质量异议的，乙方应在收到甲方书面异议后5日内派人处理并提出解决方案，
                    由此产生费用的， 由责任方承担。
                    <br />
                    5、 相关事项
                    <br />
                    （1）按时交货
                    <br />
                    如乙方未能备齐货物或未能按时交货，
                    乙方应于甲方通知乙方后一个工作日内，
                    退还未交付货物对应的货款。
                    <br />
                    （2）货物安全
                    <br />
                    因乙方的原因包括但不限于发生质量问题、权属争议、未按时交货、逾期未开具发票等，
                    由乙方负责处理及赔偿，
                    如因乙方原因导致甲方被追究任何法律责任的，
                    甲方有权要求乙方承担全额赔偿责任。
                    <br />
                    （3） 时间约定
                    <br />
                    如在《发货通知函》中注明的时间范围内，
                    非因甲方、大大买钢平台客户的原因或不可抗力，
                    造成大大买钢平台客户无法按时提货或无法足量提货，
                    导致甲方被大大买钢平台客户追究任何法律责任的，
                    甲方有权要求乙方承担全额赔偿责任。
                    <br />
                    （4） 收退款指定账号：
                    <br />
                    甲方收款指定账号：
                    <br />
                    账号： 8111601012100312273
                    <br />
                    户名： 大汉电子商务有限公司
                    <br />
                    开户行： 中信银行长沙红旗区支行
                    <br />
                    甲方退款指定账号：
                    <br />
                    账号： 8111601012100312273
                    <br />
                    户名： 大汉电子商务有限公司
                    <br />
                    开户行： 中信银行长沙红旗区支行
                    <br />
                    乙方收款指定账号：
                    <br />
                    账号：
                    <input
                      type="text"
                      name="party_b_collection_account"
                      disabled
                      v-model="form.BsNum"
                      class="contractInput"
                    />
                    <br />
                    户名： {{ form.EnterpName }} <br />
                    开户行：
                    <input
                      type="text"
                      name="party_b_collection_bank"
                      v-model="form.BsBank"
                      disabled
                      class="contractInput"
                    />
                    <br />
                    <span style="font-weight: bold">第三条、 保密条款</span>
                    <br />
                    1、
                    甲乙双方对于因本协议从对方获取的文件资料和商业秘密负有保密义务。双方应采取合理有效的保密措施，
                    避免对方保密信息被无关人员知晓。
                    <br />
                    2、 因违反保密条款给对方造成损失的，
                    违约方应向对方承担赔偿责任。
                    <br />
                    3、
                    除非公众已知悉或已通过合法途径公开披露的，该等保密义务不因本协议的无效、
                    解除、 履行完毕而终止。
                    <br />
                    <span style="font-weight: bold">第四条、 违约责任</span>
                    <br />
                    1、
                    本合同签订后，任何一方违反本合同的相关约定，违约方应按合同项下单笔订单总金额每
                    日向守约方支付0.5‰的违约金，违约金不足以弥补守约方损失的，应赔偿守约方损失。
                    如违约方违约情形超过<input
                      type="text"
                      name="default_overtake_day"
                      v-model="form.DefaultmoreDay"
                      class="contractInput"
                      disabled
                    />日的， 守约方有权终止合同。
                    <br />
                    2、
                    乙方如因发生质量问题、权属争议、未按时交货、逾期未开具发票等原因，导致甲方受到损失的，
                    乙方应承担全额赔偿责任。
                    <br />
                    3、
                    乙方放货前，未确认甲方《发货通知函》内的甲方提货专用章而放货的，应承担由此导致的甲方的一切损失。
                    <br />
                    4、 其他违约情形按《中华人民共和国民法典》执行。
                    <br />
                    <span style="font-weight: bold">第五条、 争议解决</span>
                    <br />
                    本协议在履行过程中发生争议时， 双方应协商解决； 协商不成的，
                    向甲方所在地有管辖权的人民法院提起诉讼，
                    因诉讼产生的一切费用， 包括但不限于案件受理费、 鉴定费、
                    保全费、 合理的律师费等由败诉方承担。
                    <br />
                    <span style="font-weight: bold">第六条、 合作期限</span>
                    <br />
                    双方合作期限自本协议生效之日起至2024年12月31日止。
                    协议期满前一个月内， 双方如需继续合作的可续签协议；
                    如双方到期未续签协议， 本协议自动终止。 合作期限内，
                    甲乙双方有权视合作情况暂停乙方报价或解除本协议，
                    并及时通知对方。
                    <br />
                    <span style="font-weight: bold">第七条、 其他</span>
                    <br />
                    1、 甲乙双方合作期间，乙方如需变更指定传真号、联系人资料、
                    银行账户资料等重要信 息，
                    可致电大大买钢平台客服热线（400-888-2566）并根据指示办理变更操作。
                    <br />
                    2、
                    甲乙双方合作期间，若乙方发现甲方工作人员或其他第三方人员的行为超出本协议约定的范围，
                    应当通过客服热线或其他方式与甲方核实确认。
                    甲方工作人员或其他第三方人员越权代理的行为，
                    在未经甲方书面确认前属于无效代理行为， 不构成表见代理。
                    若甲方对单次具体的越权代理的行为予以追认，
                    不视为甲方追认该代理人的其他越权代理行为。
                    越权代理行为及甲方的追认属于独立行为， 不构成双方交易习惯。
                    <br />
                    3、
                    乙方确认，除非经另行书面通知变更，本协议落款处所列示的乙方单位地址同时
                    作为 其在本协议纠纷解决时 （包括一审、 二审和执行程序）
                    相关文件和法律文书的送达地址。
                    <br />
                    4、
                    本协议所有约定均适用于甲方分公司及甲方控股的子公司和乙方，
                    本协议附件中的甲方分子公司与乙方之间所有基于本协议发生的交易，
                    任何一方均有权基于本协议向另一方主张权利。
                    <br />
                    5、 本协议经双方签订之日起生效。
                    本协议未尽事宜，双方另行协商并签订书面补充协议。
                    <br />
                    6、 本协议一式肆份， 甲方叁份， 乙方壹份，
                    具有同等法律效力。
                    <br />
                    7、
                    乙方确认已阅读、知晓并充分理解大大买钢平台公示的相关规则，
                    并对本协议承诺事项作出承诺，愿意承担本协议确定的各项责任。
                    <br />
                    附件 1： 《发货通知函》
                    <br />
                    附件 2： 《变更发货通知函》
                    <br />
                    附件 3： 《分子公司名录》
                    <br />
                    附件 4： 《廉洁协议》
                    <br />
                    <div class="bottom" style="">
                      <ul style="margin: 0; padding: 0">
                        <li>
                          <span style="padding-right: 444px"
                            >甲　　　方：大汉电子商务有限公司</span
                          ><span>乙　　　方：{{ form.EnterpName }}</span>
                        </li>
                        <li style="margin-top: 20px">
                          <span style="padding-right: 216px"
                            >地　　　址：湖南省长沙市望城区经济开发区
                            普瑞大道南侧金桥国际</span
                          ><span
                            >地　　　址：<input
                              type="text"
                              name="address"
                              v-model="form.Address"
                              disabled
                              class="contractInput"
                          /></span>
                        </li>
                        <li style="margin-top: 20px">
                          <span style="padding-right: 604px">法定代表人：</span
                          ><span
                            >法定代表人：<input
                              type="text"
                              name="representative"
                              v-model="form.Legalperson"
                              disabled
                              class="contractInput"
                          /></span>
                        </li>
                        <li style="margin-top: 20px">
                          <span style="padding-right: 604px"> 联系　电话：</span
                          ><span
                            >联系　电话：<input
                              type="text"
                              name="phone"
                              disabled
                              v-model="form.Phone"
                              class="contractInput"
                          /></span>
                        </li>
                      </ul>
                    </div>
                    <div style="text-align: center">
                      <img
                        src="./img/supplier_company_contract1.png"
                        style="width: 1008px"
                      />
                      <img
                        src="./img/supplier_company_contract2.png"
                        style="width: 1008px"
                      />
                      <img
                        src="./img/supplier_company_contract3.png"
                        style="width: 1008px"
                      />
                    </div>
                    <div class="contractText">廉洁协议</div>
                    <div
                      style="
                        margin: 30px auto;
                        display: flex;
                        justify-content: space-between;
                        text-indent: 0em;
                      "
                    >
                      <div style="text-align: left">
                        <div class="contractJiayi" style="text-align: left">
                          甲方： 大汉电子商务有限公司
                        </div>
                      </div>
                      <div style="text-align: left">
                        <div class="contractJiayi" style="text-align: left">
                          乙方： {{ form.EnterpName }}
                        </div>
                      </div>
                    </div>
                    <div class="contractWenzi">
                      甲乙双方签署了<span style="font-weight: bold"
                        >大大买钢《平台合作服务协议》</span
                      >
                      合同（以下简称原合同），为营造健康
                      商业环境和建立正常商业合作关系，甲乙双方经友好协商签订本协议，并作为双方共同遵守的廉
                      洁合作行为准则执行。<br />
                      <span style="font-weight: bold">一、甲方责任</span>
                      <br />1、甲方有责任向乙方介绍大汉（即甲方或甲方所在的大汉集团）廉洁合作相关规定。
                      <br />
                      2、甲方严禁甲方人员在业务合作过程出现下列行为（包括不限于）：
                      <br />（1） 商业贿赂：
                      甲方人员收受或向乙方及乙方人员主动索要各种形式的回扣、现金、实物、证券、礼券等有
                      价物品，或接受并参与乙方及乙方人员提供的旅游、高档宴请及其他可能影响职务行为公正履行
                      的活动； <br />（2） 行贿及其他：
                      甲方人员要求乙方或乙方人员配合或协助进行各种形式的行贿（包括但不限于提供回扣、现
                      金、实物、证券、礼券等有价物品）、不当利益输送在内的各类违法活动；
                      <br />（3） 利益冲突：
                      甲方人员未经甲方批准，以本人或其亲属名义于乙方或其关联单位处直接或变相参股、任职、兼
                      职或获取其他利益，甲方禁止引入大汉离职人员注册或参股的公司成为大汉供应商；
                      <br />（4） 串标围标：
                      在甲方业务范围内，甲方员工授意乙方配合进行串标或围标等违规违法行为；
                      <br />
                      （5） 资金往来及关联交易：
                      甲方人员（含甲方人员授意的其他人员，如甲方人员的亲属、朋友、同学、下属等）以任何
                      理由向乙方（含关联单位）或乙方人员借贷资金、要求垫资、担保或其他形式融资行为，以及通
                      过房屋买卖、代持房产等其他使得甲方人员利用职务之便获取不当利益或损害甲方品牌、商誉、
                      利益等（包括但不限于甲方认为甲方人员未公正履行职务）的关联交易行为。
                      <br />
                      3、对于乙方举报甲方人员违反廉洁合作禁止项的情况，甲方负有保密义务，并应及时组织
                      调查，将最终调查结果反馈乙方，并可依据甲方或甲方所在大汉集团举报奖励政策给予乙方适当
                      奖励。 <br />
                      <span style="font-weight: bold">二、乙方责任</span>
                      <br />1、乙方应保证乙方人员了解本协议中廉洁合作禁止项的相关规定，并遵照执行。
                      <br />
                      2、乙方承诺并保证，与甲方开展合作时不存在（且在与甲方开展合作的过程中不得有）：
                      <br />（1）
                      大汉在职员工直接或变相持股或控制乙方公司或其关联单位，但公开市场股票投资不在前述限制；
                      <br />（2）
                      大汉在职员工在乙方公司或其关联单位处任职、兼职、借贷资金或获取其他利益的情况。
                      <br />
                      3、乙方及乙方应确保乙方人员严格遵守以下廉洁合作约定（包括不限于）：
                      <br />
                      （1）
                      严禁商业贿赂：乙方及乙方人员不得向甲方人员提供任何形式的回扣、现金、实物、证
                      券、礼券等有价物品或旅游、高档宴请等其他可能影响职务行为公正履行的活动；乙方及乙方人
                      员不得向甲方聘请的第三方单位及人员提供任何形式的赠与、贿赂等，以谋求在检测、鉴定、验
                      收等方面的便利；乙方及乙方人员也不得接受甲方的承包商、供应商等相关单位及人员任何形式
                      的赠予、贿赂等，以提供在检测、鉴定、验收等方面的便利；
                      <br />
                      （2）
                      严禁行贿及其他违法：乙方及乙方人员不得接受甲方人员要求配合或协助进行各种形式
                      的行贿（包括但不限于提供回扣、现金、实物、证券、礼券等有价物品）、不当利益输送在内违
                      法行为的请求； <br />
                      （3）
                      利益冲突及其他披露：乙方在与甲方开展合作前，如出现以下任何情形之一，须在投标
                      前，主动以书面方式向甲方完整、如实披露相关信息；若在与甲方合作过程中，出现以下任何情
                      形之一的，须在相关情形发生后 10
                      天内，以书面方式向甲方完整、如实披露相关信息：
                      ①大汉在职员工的近亲属于乙方公司或其关联单位处直接或变相持股（注：公开市场股票投
                      资不属于乙方协议披露义务）、控制、任职、兼职、借贷资金或获取其他利益的情况；
                      ②大汉离职员工或其近亲属于乙方公司或其关联单位处直接或变相持股（注：公开市场股票
                      投资不属于乙方协议披露义务）、控制、任职或兼职的情况；
                      ③乙方法定代表人、股东、实际业务控制人、对接人两年内以其他公司名义参与大汉业务合
                      作的情况。 <br />
                      （4）
                      严禁串标围标：在甲方业务范围内，乙方及乙方人员应拒绝甲方员工授意配合进行串标
                      或围标等违规违法操作的请求；乙方及乙方员工不得串谋其他单位，在大汉业务中进行串标或围
                      标等违规违法操作。 <br />
                      （5）
                      资金往来及关联交易：乙方人员（含乙方人员授意的其他人员，如乙方人员的亲属、朋
                      友、同学、下属等）以任何理由向甲方
                      （含关联单位）或甲方人员借贷资金、要求垫资、担保或其
                      他形式融资行为，以及双方存在房屋买卖（合同款抵房除外）、代持房产等其他形式的关联交易，
                      利用甲方人员职务之便获取不当利益或损害甲方品牌、商誉、利益等行为。
                      <br />
                      4、乙方在与甲方开展合作前，有责任向甲方披露乙方实际控制人信息，包括不限于姓名、身
                      份证号码等信息。 <br />
                      5、乙方有责任接受甲方对乙方在合作期间廉洁合作协议执行情况的监督，并对甲方相关调查工
                      作主动配合。 <br />
                      6、乙方有责任就甲方人员或甲方业务合作单位人员营私舞弊、弄虚作假等违反职业道德准则的
                      行为及时向甲方举报，乙方在主动举报的情形下，其参与的上述行为将予以免责。
                      甲方受理举报的常设部门及联系方式为： <br />（1）
                      举报受理部门：大汉控股集团有限公司监察部 <br />（2）
                      举报邮箱：jiancha@dahangroup.com
                      <br />（3）举报电话：0731-88207935
                      <br />（4）举报地址：湖南省长沙市望城区普瑞大道 1555
                      号金桥国际未来城 4 栋 512 办公室 <br />
                      <span style="font-weight: bold">三、违约责任</span> <br />
                      1、如乙方或其人员违反本协议第二条乙方责任中第 2 款、第 3
                      款约定的，甲方有权解除原合
                      同且无需承担任何违约责任，乙方除应向甲方赔偿由此给甲方造成的损失外，乙方还承诺向甲方
                      支付相当于合同总价
                      30%或违规事件所涉及金额之和的十倍作为违约金（二者从高）。对乙方相
                      关责任人员涉嫌违法犯罪的，甲方有权提交司法机关处理，有权公开通报处理情况。
                      <br />
                      2、如乙方拒不配合甲方对乙方在合作期间内的廉洁合作监督及调查工作的，或者被证实存在隐
                      瞒信息、提供虚假信息和伪证行为的，乙方同意甲方有权视情节严重程度，对原合同的履行采取暂停
                      支付业务进度款、终止合作、追究经济损失、解除合同等措施，且无需承担任何违约责任。
                      <br />
                      3、如乙方在合作期间内出现违反本协议约定的行为，甲方有权视情节严重程度，将乙方及其关
                      联单位列入不诚信合作方名单，在甲方/大汉内外进行公示。
                      <br />
                      <span style="font-weight: bold">四、其他</span> <br />
                      1、本协议是原合同的补充协议，与原合同有同等法律效力。本合同未作约定的事项，仍以原合
                      同约定为准。本协议约定与原合同不一致的，以本协议为准
                      <br />
                      2、本协议经双方盖章后生效。

                      <div class="bottom">
                        <ul style="margin: 0; padding: 0">
                          <li>
                            <span style="padding-right: 444px"
                              >甲　　　方：大汉电子商务有限公司</span
                            ><span>乙　　　方：{{ form.EnterpName }}</span>
                          </li>
                          <li style="margin-top: 20px">
                            <span style="padding-right: 604px">
                              盖　　　章：</span
                            ><span>盖　　　章：</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
          <div
            style="color: #333333; font-size: 22px; margin-top: 24px"
            v-if="initInfo.Status != 1"
          >
            经营范围
            <span style="margin-left: 24px; color: #666666; font-size: 14px"
              >设置您的经营范围，平台将为您推荐更合适的客户采购需求单</span
            >
          </div>
          <div class="uploadDiv" v-if="initInfo.Status == null">
            <el-form
              :model="formData"
              :rules="rules"
              ref="ruleForm"
              label-width="80px"
            >
              <el-form-item label="主营品类" prop="MainCategory">
                <el-select
                  multiple
                  collapse-tags
                  v-model="formData.MainCategory"
                  placeholder="请选择主营品类"
                >
                  <el-option
                    v-for="item in mainAreaList"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主营区域" prop="MainArea">
                <el-select
                  multiple
                  collapse-tags
                  v-model="formData.MainArea"
                  placeholder="请选择主营区域"
                >
                  <el-option
                    v-for="item in areaList"
                    :label="item.provinceName"
                    :value="item.provinceName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div
            class="uploadDiv"
            v-if="initInfo.Status == 0 || initInfo.Status == 2"
          >
            <el-form
              :model="formData"
              :rules="rules"
              ref="ruleForm"
              label-width="80px"
            >
              <el-form-item label="主营品类" prop="MainCategory">
                {{ initInfo.MainCategory }}
                <!--                <el-select v-model="formData.MainCategory" placeholder="请选择主营品类" >-->
                <!--                  <el-option v-for="item in mainAreaList" :label=item :value=item></el-option>-->
                <!--                </el-select>-->
              </el-form-item>
              <el-form-item label="主营区域" prop="MainArea">
                {{ initInfo.MainArea }}
                <!--                <el-select v-model="formData.MainArea" placeholder="请选择主营区域">-->
                <!--                  <el-option v-for="item in areaList" :label=item.provinceName :value=item.provinceName></el-option>-->
                <!--                </el-select>-->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div
        v-if="type == 0"
        class="stepBtn stepBtnClick contractAdd contractAgreement"
        style="cursor: pointer"
        @click="supplier"
      >
        保存,下一步
      </div>
      <div
        v-if="type == 1 && initInfo.Status == null"
        class="stepBtn stepBtnClick contractAdd contractAgreement"
        style="cursor: pointer"
        @click="supplier2"
      >
        提交入驻资料
      </div>
    </div>
  </comprehensive>
</template>

<script>
import * as Api from "@/utils/http";
import util from "@/libs/util.js";
import { onInputLimit } from "@/utils/index";
import comprehensive from "../../components/comprehensive.vue";
import ddmgUploadImg from "../../components/ddmg-upload-img/index.vue";
import { mapState, mapActions } from "vuex";
import storage from "@/utils/storage";
export default {
  name: "bid",
  components: {
    comprehensive,
    ddmgUploadImg,
  },
  data() {
    return {
      type: 0,
      userInfo: {},
      pdfUrl: "",
      initInfo: {},
      dialogVisible: false,
      dialogImageUrl: "",
      dialogVisible2: false,
      imgUrlArr: [],
      mainAreaList: [
        "建材",
        "板材",
        "型材",
        "管材",
        "建筑辅材",
        "优特钢",
        "其他",
        "酒",
      ],
      areaList: [],
      formData: {
        MainArea: "", //类型：String  必有字段  备注：主营区域
        MainCategory: "", //类型：String  必有字段  备注：主营品类
      },
      rules: {
        MainArea: [
          {
            required: true,
            message: "请选择主营区域",
            trigger: "change",
          },
        ],
        MainCategory: [
          {
            required: true,
            message: "请选择主营品类",
            trigger: "change",
          },
        ],
      },
      form: {
        ContractNo: "",
        EnterpID: "", //类型：String  必有字段  备注：企业ID
        EnterpName: "", //类型：String  必有字段  备注：企业名称
        Faxnumber: "", //类型：String  必有字段  备注：传值号码
        Receiver: "", //类型：String  必有字段  备注：接收人
        WechatNo: "", //类型：String  必有字段  备注：微信号
        MobliePhone: "", //类型：String  必有字段  备注：电话号码
        PayDay: "", //类型：String  必有字段  备注：货款结算日
        PayReceiver: "", //类型：String  必有字段  备注：货款结算接收人
        PayPhone: "", //类型：String  必有字段  备注：货款结算电话
        PayAddress: "", //类型：String  必有字段  备注：货款结算地址
        BsNum: "", //类型：String  必有字段  备注：乙方收款指定账号
        BsBank: "", //类型：String  必有字段  备注：乙方收款开户行
        DefaultmoreDay: "", //类型：String  必有字段  备注：违约超过日
        Address: "", //类型：String  必有字段  备注：地址
        Legalperson: "", //类型：String  必有字段  备注：法人代表
        Phone: "", //类型：String  必有字段  备注：联系方式
        DdmgWholeRoleID: 1, //类型：String  必有字段  备注：企业会员角色ID
      },
    };
  },
  created() {},
  methods: {
    onInputLimit,
    ...mapActions("ddmg/bid", [
      "adminApplyPermissionInit",
      "supplierContract",
      "print",
      "adminApplyPermission",
      "modifyContract",
    ]),
    ...mapActions("ddmg/cart", ["getArea"]),
    onInputPayDay(val) {
      if (this.form.PayDay) {
        let value = this.onInputLimit(this.form.PayDay, 0);
        if (value > 31) value = 31;
        if (value <= 0) value = 1;
        this.form.PayDay = value;
      } else {
        this.form.PayDay = "";
      }
    },
    //跳转集采模块
    goJC() {
      this.$router.push({ name: "bid" });
    },
    //驳回后重新申请
    again() {
      this.initInfo.Status = null;
    },
    //提交合同
    supplier2() {
      if (this.imgUrlArr.length == 0) {
        this.$message.warning("请上传平台合作服务协议的附件图片!");
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            let body = {
              ApplyEnterpId: this.form.EnterpID, //类型：String  必有字段  备注：企业ID
              ApplyEnterpName: this.form.EnterpName, //类型：String  必有字段  备注：企业名称
              ApplyRoleId: "1", //类型：String  必有字段  备注：角色ID
              ApplyDataUrl:
                this.imgUrlArr.length > 0 ? this.imgUrlArr.join(",") : "", //类型：String  必有字段  备注：附件
              MainArea: this.formData.MainArea.join(","), //类型：String  必有字段  备注：主营区域
              userid: this.userInfo.userid, //类型：String  必有字段  备注：用户ID
              MainCategory: this.formData.MainCategory.join(","), //类型：String  必有字段  备注：主营品类
            };
            this.adminApplyPermission(body).then((res) => {
              this.$message.success("提交成功");
              this.init();
            });
            // if (this.imgUrlArr.length == 0) {
            //     this.$message("请上传合同");
            // } else {
            //
            // }
          } else {
            return false;
          }
        });
      }
    },
    //上传图片
    //  UploadUrl(data) {
    // console.log(data);
    // let formData = new FormData();
    // formData.append("file", data.file);
    // formData.append("scope", "userCompanyRelate");
    // Api.startReq("base/uploadImage", formData, "post").then((res) => {
    //   let item = {
    //     attachment_url: res.data.img_path,
    //     attachment_name: data.file.name,
    //     attachment_format: data.file.type,
    //   };
    //   this.imgUrlArr.push(item);
    //   this.$message(res.message);
    // });
    // },
    // beforeAvatarUpload(file) {
    //     const isJPG =
    //         file.type == "image/png" ||
    //         file.type == "image/jpg" ||
    //         file.type == "image/jpeg" ||
    //         file.type == "image/bmp" ||
    //         file.type == "image/gif";
    //     if (!isJPG) {
    //         this.$message.error("上传图片只能是JPG、PNG、GIF、bmp格式!");
    //     }
    //     return isJPG;
    // },
    //点击放大图片
    handlePictureCardPreview(url) {
      this.dialogVisible = true;
      this.dialogImageUrl = url;
    },
    //删除提交图片
    // delImg(item){
    //     this.imgUrlArr.splice(this.imgUrlArr.indexOf(item), 1)
    // },
    //在线打印-打开PDF页面
    getPrint() {
      this.print(this.form.ContractNo).then((res) => {
        this.pdfUrl = res.resultdata;
        window.open(this.pdfUrl, "_blank");
      });
    },
    //查看
    lookPrint() {
      this.dialogVisible2 = true;
    },
    //修改
    edit() {
      this.type = 0;
    },
    //详情初始化
    init() {
      this.getArea().then((obj) => {
        this.areaList = obj.resultdata.provinces;
      });
      if (storage.getStorage("userInfo")) {
        this.userInfo = storage.getStorage("userInfo");
      }
      let body = {
        EnterpID: this.userInfo.company_id,
      };
      this.adminApplyPermissionInit(body).then((res) => {
        this.initInfo = res.resultdata;
        if (this.initInfo.ApplyUrl) {
          this.imgUrlArr = this.initInfo.ApplyUrl.split(",");
        }
        if (this.initInfo.ddmgenterpContract) {
          this.form = this.initInfo.ddmgenterpContract;
          this.type = 1;
        } else {
          this.form.EnterpName = this.initInfo.EnterpName;
          this.form.EnterpID = this.initInfo.ID;
          this.type = 0;
        }
      });
    },
    //提交表单
    supplier() {
      if (
        this.form.Faxnumber == "" ||
        this.form.Receiver == "" ||
        this.form.WechatNo == "" ||
        this.form.MobliePhone == "" ||
        this.form.PayDay == "" ||
        this.form.PayReceiver == "" ||
        this.form.PayPhone == "" ||
        this.form.PayAddress == "" ||
        this.form.BsNum == "" ||
        this.form.BsBank == "" ||
        this.form.DefaultmoreDay == "" ||
        this.form.Address == "" ||
        this.form.Legalperson == "" ||
        this.form.Phone == ""
      ) {
        this.$message.error("请确认合同信息填写完整！");
      } else {
        if (this.form.ContractNo) {
          this.modifyContract(this.form).then((res) => {
            if (res.msgcode == 1) {
              this.$message.success("操作成功");
              if (this.initInfo.Status == null) {
                this.type = 1;
              } else {
                this.init();
              }
            }
          });
        } else {
          this.supplierContract(this.form).then((res) => {
            if (res.msgcode == 1) {
              this.form.ContractNo = res.resultdata || ""; // 解决保存信息进入提交入驻资料页面时，点击打印报没有合同编号问题；
              this.$message.success("操作成功");
              if (this.initInfo.Status == null) {
                this.type = 1;
              } else {
                this.init();
              }
            }
          });
        }
      }
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState("ddmg/bid", {
      pageLoading: (state) => state.pageLoading,
    }),
  },
};
</script>
<style>
.stepTop2btn {
  width: 110px;
  height: 32px;
  background-color: #e73352;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  color: #ffffff;
}
.stepTop2 {
  width: 1200px;
  min-height: 122px;
  margin: 20px auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 6%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.el-upload--picture-card {
  width: 73px;
  height: 73px;
  background-color: #fff9fa;
  box-shadow: none;
  border: 1px dashed #e6e6e6;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
}
.el-icon-plus:before {
  content: "\A\E6D9\A上传图片" !important;
  white-space: pre !important;
  font-size: 12px !important;
}
.el-upload--picture-card i {
  font-size: 22px;
  color: #3d7dff;
}
</style>
<style lang="scss" scoped>
.uploadView {
  width: 73px;
  height: 73px;
  margin-left: 27px;
  position: relative;
}
.imgLattice {
  width: 16px;
  height: 16px;
  background: url(./img/uploadgb.png);
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
}
.uploadViewText {
  width: 73px;
  height: 20px;
  color: #333333;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin-top: 4px;
  text-align: center;
}
.uploadLattice {
  width: 100%;
  height: 100%;

  cursor: pointer;
}
.uploadDiv {
  margin: 20px auto;
  display: flex;
}
.uploadInputDiv {
  width: 73px;
  height: 73px;
  line-height: 73px;
  text-align: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  margin-right: 20px;
  background-color: #fff9fa;
  box-shadow: none;
  border: 1px dashed #e6e6e6;
  border-radius: 4px;
}
.uploadImg {
  width: 16px;
  height: 16px;
  position: absolute;
  /* z-index: 99; */
  left: 30px;
  top: 15px;
}
.uploadInput {
  position: absolute;
  left: 0;
  width: 73px;
  height: 73px;
  background-color: #fff9fa;
  box-shadow: none;
  border: 1px dashed #e6e6e6;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0;
}
.uploadText {
  position: absolute;
  /* z-index: 99; */
  top: 10px;
  width: 73px;
  height: 73px;
  font-weight: 500;
  color: #3d7dff;
  font-size: 14px;
}
.uploadDivTag {
  background-color: #fff9fa;
  border-radius: 4px;
  width: 147px;
  height: 20px;
  color: #666666;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 32px 20px 0;
  line-height: 28px;
}
.contractButton {
  height: 20px;
  color: #ef2147;
  font-size: 14px;
  line-height: 28px;
  margin: 0 12px;
  cursor: pointer;
}
.stepBtnClick {
  background-color: #ef2147;
  color: #ffffff;
  cursor: pointer;
}
.stepBtn {
  width: 240px;
  height: 40px;
  margin: 40px auto;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}
.stepTop3 {
  width: 1152px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 6%);
  margin: 20px auto;
  padding: 24px;
  input[type="text"] {
    resize: none;
    -webkit-appearance: none;
    border-radius: 0;
    outline: none;
    height: 22px;
  }
  .contractInput {
    padding: 0px 20px;
    width: 220px;
    margin: 2px 5px 10px 5px;
    box-shadow: none;
    border: 1px solid rgb(118, 118, 118);
  }
  .bottom {
    margin: 30px auto;
    text-indent: 0em;
    height: 260px;
    background-position: 8% 5%;
    background-repeat: no-repeat;
    background-image: url(./img/chapter.png);
  }
  .contract {
    margin: 20px auto;
    height: 500px;
    margin-bottom: 30px;
    padding: 24px;
    overflow: auto;
  }
  .contractJiayi {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .contractWenzi {
    /* width: 1200px; */
    text-align: left;
    font-size: 16px;
    text-indent: 2em;
  }

  .stepTable {
    display: flex;
    margin-top: 24px;
    padding-bottom: 38px;
    border-bottom: 1px solid #eeeeee;
  }
  .stepTable_left {
    width: 50%;
  }
  .stepTable_tr {
    color: #333333;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 20px auto;
  }
  .stepTable_td {
    color: #666666;
    font-size: 14px;
    min-width: 80px;
  }
  .hiddenOne {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    word-wrap: break-word;
  }
  .stepTable_right {
    width: 50%;
  }
  .stepTable_tr {
    color: #333333;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 20px auto;
  }
}
.stepTop1 {
  width: 1200px;
  height: 120px;
  margin: 20px auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 6%);
  text-align: center;
  .stepTopText {
    width: 1068px;
    margin: 8px auto;
    text-align: left;
  }
}
</style>
